import { AuthFormContent, AuthMode } from "./types";

export const AUTH_FORM_CONTENT: Record<
  AuthMode.LOGIN | AuthMode.SIGN_UP,
  Record<AuthFormContent, string>
> = {
  [AuthMode.LOGIN]: {
    [AuthFormContent.PHONE_FIELD_TITLE]: "Enter your phone number:",
    [AuthFormContent.EMAIL_FIELD_TITLE]: "Enter your email:",
    [AuthFormContent.SUBMIT_BUTTON]: "Log In",
    [AuthFormContent.SWITCH_AUTH_MODE_INSTRUCTIONS]: "Don't have an account?",
    [AuthFormContent.SWITCH_AUTH_MODE_CTA]: "Sign Up",
    [AuthFormContent.SUPPORT_EMAIL_SUBJECT]: "Log In",
  },
  [AuthMode.SIGN_UP]: {
    [AuthFormContent.PHONE_FIELD_TITLE]: "Sign up with your phone number:",
    // Currently we don't allow sign up using email.
    [AuthFormContent.EMAIL_FIELD_TITLE]: "N/A",
    [AuthFormContent.SUBMIT_BUTTON]: "Sign Up",
    [AuthFormContent.SWITCH_AUTH_MODE_INSTRUCTIONS]: "Already have an account?",
    [AuthFormContent.SWITCH_AUTH_MODE_CTA]: "Log In",
    [AuthFormContent.SUPPORT_EMAIL_SUBJECT]: "Sign Up",
  },
};
