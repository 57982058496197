export interface LoginSignUpFormFields {
  phoneNumber: string;
  email: string;
}

export enum AuthMethod {
  PHONE = "phone",
  EMAIL = "email",
}

/**
 * TODO - there are more error codes to add.
 * See: https://github.com/ClipboardHealth/clipboard-health/blob/development/projects/backend-main/src/routes/agentProfile/controller/requestOTP.ts#L42
 */
export enum AuthOtpErrorCode {
  AGENT_NOT_FOUND = "AgentNotFound",
  AGENT_SOFT_DELETED = "AccountSoftDeleted",
  INVALID_PHONE_NUMBER = "InvalidPhoneNumber",
  FIREBASE_NEW_USER_CREATION_FAILURE = "FailedToCreateNewFirebaseUser",
  FIREBASE_SYNC_USER_FAILURE = "FailedToSyncFirebaseUser",
  FIREBASE_TOKEN_GENERATION_FAILURE = "FirebaseFailedGenerateToken",
  SMS_REQUEST_FAILED = "SMSFailed",
}
