import { getAppUrl } from "./getAppUrl";

const EMAIL_VERIFY_SENT_PATH = "/welcome/login/linkVerify";

export const getLoginUrl = (email: string): string => {
  const appDomain = getAppUrl();
  try {
    const loginUrl = new URL(EMAIL_VERIFY_SENT_PATH, appDomain);
    loginUrl.searchParams.set("email", email);
    return loginUrl.toString();
  } catch (error) {
    throw new Error(
      `Problem creating login URL for appDomain: ${appDomain}. Original error: ${String(error)}`
    );
  }
};
