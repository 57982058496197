import { Box, Container } from "@mui/material";
import { useLegacyStateContext } from "@src/appV2/LegacyStateBridge/context";
import { FullScreenPageLayout } from "@src/appV2/lib/Layouts/FullScreen/Page";
import { type ReactElement } from "react";
import { Redirect } from "react-router-dom";

import { AuthFooter } from "../components/Footer";
import { AuthHeadline } from "../components/Headline";
import { LoginSignUpForm } from "./Form";

export function LoginSignUpPage(): ReactElement {
  const { session } = useLegacyStateContext();
  if (session.isAuthorized) {
    return <Redirect to="/home" />;
  }

  return (
    <FullScreenPageLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          gap: 2,
          paddingTop: {
            xs: 2,
            sm: 2,
            md: 4,
          },
          paddingBottom: {
            xs: 1,
            sm: 1,
            md: 2,
          },
        }}
      >
        <Container maxWidth="lg">
          <AuthHeadline />
          <Container
            maxWidth="md"
            sx={{
              marginTop: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 6,
              },
            }}
          >
            <LoginSignUpForm />
          </Container>
        </Container>
        <AuthFooter />
      </Box>
    </FullScreenPageLayout>
  );
}
