import { Text } from "@clipboard-health/ui-react";
import { Box } from "@mui/material";
import { EmailField, PhoneField } from "@src/appV2/lib";
import { type ReactElement } from "react";

import { AuthFormContent, useAuthMode } from "../libs";
import { AuthMethod } from "../types";

interface AuthFormFieldsProps {
  authMethod: AuthMethod;
}

export function AuthFormFields(props: AuthFormFieldsProps): ReactElement {
  const { authMethod } = props;
  const { authFormContent } = useAuthMode();

  return (
    <Box>
      {authMethod === AuthMethod.PHONE ? (
        <>
          <Text paragraph>{authFormContent[AuthFormContent.PHONE_FIELD_TITLE]}</Text>
          <PhoneField name="phoneNumber" />
        </>
      ) : (
        <>
          <Text paragraph>{authFormContent[AuthFormContent.EMAIL_FIELD_TITLE]}</Text>
          <EmailField name="email" />
        </>
      )}
    </Box>
  );
}
