import { isPlatform } from "@ionic/core";
import { environmentConfig, isDevelopmentNodeEnvironment } from "@src/appV2/environment";

export const getAppUrl = (): string => {
  const isDevelopment = isDevelopmentNodeEnvironment() || window.location.hostname === "localhost";

  if (isPlatform("capacitor")) {
    return environmentConfig.REACT_APP_WEB_APP_URL;
  }

  if (isDevelopment) {
    return `http://localhost:4300`;
  }

  return `https://${window.location.hostname}`;
};
