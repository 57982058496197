export enum AuthMode {
  LOGIN = "login",
  SIGN_UP = "sign-up",
  AD_HOC = "ad-hoc",
}

export enum AuthFormContent {
  PHONE_FIELD_TITLE,
  EMAIL_FIELD_TITLE,
  SUBMIT_BUTTON,
  SWITCH_AUTH_MODE_INSTRUCTIONS,
  SWITCH_AUTH_MODE_CTA,
  SUPPORT_EMAIL_SUBJECT,
}
