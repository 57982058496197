import { useParams } from "react-router-dom";

import { AUTH_FORM_CONTENT } from "./content";
import { AuthMode } from "./types";

export function useAuthMode() {
  const { authMode } = useParams<{ authMode: AuthMode.LOGIN | AuthMode.SIGN_UP }>();

  const authFormContent = AUTH_FORM_CONTENT[authMode];
  return {
    authMode,
    authModeIsLogin: authMode === AuthMode.LOGIN,
    authModeIsSignUp: authMode === AuthMode.SIGN_UP,
    authFormContent,
  };
}
