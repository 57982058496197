import validator from "validator";
import { z } from "zod";

import { AuthMode } from "../libs";
import { AuthMethod } from "../types";

const emailLoginFormSchema = z
  .object({
    email: z
      .string()
      .refine((value) => validator.isEmail(value), {
        message: "Please provide a valid email address.",
      })
      .or(z.literal("")),
  })
  .superRefine((values, context) => {
    if (!values.email) {
      context.addIssue({
        message: "Please provide an email address to log in.",
        code: z.ZodIssueCode.custom,
        path: ["email"],
      });
    }
  });

const phoneNumberLoginFormSchema = z
  .object({
    phoneNumber: z
      .string()
      .refine((value) => validator.isMobilePhone(value, "en-US"), {
        message: "Please provide a valid phone number.",
      })
      .or(z.literal("")),
  })
  .superRefine((values, context) => {
    if (!values.phoneNumber) {
      context.addIssue({
        message: "Please provide a phone number to log in.",
        code: z.ZodIssueCode.custom,
        path: ["phoneNumber"],
      });
    }
  });

const signUpFormSchema = z
  .object({
    phoneNumber: z
      .string()
      .refine((value) => validator.isMobilePhone(value, "en-US"), {
        message: "Please provide a valid phone number.",
      })
      .or(z.literal("")),
  })
  .superRefine((values, context) => {
    if (!values.phoneNumber) {
      context.addIssue({
        message: "Please provide a phone number to log in.",
        code: z.ZodIssueCode.custom,
        path: ["phoneNumber"],
      });
    }
  });

export function getFormSchema(mode: AuthMode, authMethod: AuthMethod): Zod.Schema {
  if (mode === AuthMode.LOGIN) {
    return authMethod === AuthMethod.PHONE ? phoneNumberLoginFormSchema : emailLoginFormSchema;
  }

  if (mode === AuthMode.SIGN_UP) {
    return signUpFormSchema;
  }

  throw new Error("Invalid auth mode provided.");
}
