import { useRequestOtp } from "@src/appV2/Auth/api/useRequestOtp";
import { useCountdownTimer } from "@src/appV2/lib/utils/useCountdownTimer";
import constate from "constate";

const DEFAULT_COUNTDOWN_DURATION_IN_SECONDS = 30;

interface StepUpAuthProps {
  countdownDurationInSeconds?: number;
}

const useStepUpAuth = (props: StepUpAuthProps) => {
  const { countdownDurationInSeconds = DEFAULT_COUNTDOWN_DURATION_IN_SECONDS } = props;

  const { countdownIsLoading, startTimer, secondsLeft } = useCountdownTimer({
    durationInSeconds: countdownDurationInSeconds,
  });

  const { mutateAsync: requestOtp, isLoading: isRequestingOtp } = useRequestOtp();

  return {
    countdownIsLoading,
    startCountdownTimer: startTimer,
    countdownSecondsLeft: secondsLeft,
    requestOtp,
    isRequestingOtp,
  };
};

export const [StepUpAuthProvider, useStepUpAuthContext] = constate(useStepUpAuth);
