import { useModalState } from "@clipboard-health/ui-react";
import { useRequestAgentByPhone } from "@src/appV2/Agents/api/useAgentProfileByPhone";
import { extractPhoneNumberDigits, useToast } from "@src/appV2/lib";
import { isAxiosError } from "axios";
import { useState } from "react";

import { useRequestLoginLink } from "../../../api/useRequestLoginLink";
import { useRequestOtp } from "../../../api/useRequestOtp";
import { AuthMethod, AuthOtpErrorCode, type LoginSignUpFormFields } from "../../types";
import { getLoginUrl } from "../utils/getLoginUrl";

export function useAuthWorkflow() {
  const otpModalState = useModalState();
  const signUpInsteadPhoneModalState = useModalState();
  const signUpInsteadEmailModalState = useModalState();
  const phoneNumberExistsModalState = useModalState();
  const reactivationModalState = useModalState();

  const emailConfirmationModalState = useModalState();
  const { showErrorToast } = useToast();

  const [authMethod, setAuthMethod] = useState(AuthMethod.PHONE);

  const { mutate: requestOtp, isLoading: otpRequestIsLoading } = useRequestOtp({
    onSuccess() {
      otpModalState.openModal();
    },
    onError(error) {
      const { message = "Something went wrong while requesting your OTP code", error: errorCode } =
        error.response?.data ?? {};

      if (errorCode === AuthOtpErrorCode.AGENT_SOFT_DELETED) {
        reactivationModalState.openModal();
        return;
      }

      if (errorCode === AuthOtpErrorCode.AGENT_NOT_FOUND) {
        signUpInsteadPhoneModalState.openModal();
        return;
      }

      showErrorToast(message);
    },
  });

  const { mutate: requestLoginLink, isLoading: loginLinkRequestIsLoading } = useRequestLoginLink({
    onSuccess() {
      emailConfirmationModalState.openModal();
    },
    onError(error) {
      const {
        message = "Something went wrong while requesting your login link",
        error: errorCode,
      } = error.response?.data ?? {};

      if (isAxiosError(error) && error.response?.status === 404) {
        signUpInsteadEmailModalState.openModal();
        return;
      }

      if (errorCode === AuthOtpErrorCode.AGENT_SOFT_DELETED) {
        reactivationModalState.openModal();
        return;
      }

      showErrorToast(message);
    },
  });

  const { mutateAsync: fetchAgentByPhoneNumber } = useRequestAgentByPhone();

  async function initiateLogin(values: LoginSignUpFormFields) {
    const { phoneNumber, email } = values;

    if (phoneNumber) {
      requestOtp({
        isSignup: false,
        phone: extractPhoneNumberDigits(phoneNumber),
      });
    } else if (email) {
      /**
       * FIXME - remove this unusual side-effect.
       * https://linear.app/clipboardhealth/issue/FEF-99/authv2-via-email-should-work-on-android
       * This is a hack to make legacy v1 email auth work on Android devices
       * See https://clipboardhealth.slack.com/archives/C04MERGQG03/p1687395133072959
       * and https://app.slack.com/client/T51H5SZR7/C05EKBHPSE4/thread/C05EKBHPSE4-1687564818.822929
       * Note - this local storage value is referenced in a few places, some of which trigger side-effects
       * Instead, the verify flow should rely on a query parameter that is included in the verify URL.
       */
      localStorage.setItem("emailToVerify", email);
      requestLoginLink({
        email,
        url: getLoginUrl(email),
      });
    } else {
      throw new Error("Must provide phone number or email");
    }
  }

  async function initiateSignUp(values: LoginSignUpFormFields) {
    const { phoneNumber } = values;
    if (phoneNumber) {
      const unformattedPhoneNumber = extractPhoneNumberDigits(phoneNumber);

      const agent = await fetchAgentByPhoneNumber({
        phone: extractPhoneNumberDigits(unformattedPhoneNumber),
      });

      if (typeof agent.data === "object" && agent.data?.onboardingFlags?.isSignupCompleted) {
        phoneNumberExistsModalState.openModal();
        return;
      }

      requestOtp({
        isSignup: true,
        phone: extractPhoneNumberDigits(unformattedPhoneNumber),
      });
    } else {
      throw new Error("Must provide phone number");
    }
  }

  function getLoadingMessage() {
    if (otpRequestIsLoading) {
      return "Sending Text Message...";
    }

    if (loginLinkRequestIsLoading) {
      return "Emailing Link...";
    }

    return "";
  }

  return {
    initiateLogin,
    initiateSignUp,
    otpRequestIsLoading,
    authLoadingMessage: getLoadingMessage(),
    loginLinkRequestIsLoading,
    otpModalState,
    signUpInsteadPhoneModalState,
    signUpInsteadEmailModalState,
    emailConfirmationModalState,
    phoneNumberExistsModalState,
    reactivationModalState,
    authMethod,
    setAuthMethod,
  };
}
