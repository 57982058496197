import { IonPage } from "@ionic/react";
import { type ReactElement } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { AuthMode } from "./libs";
import { LoginSignUpPage } from "./Page";

export function IonLoginSignUpRoutes(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/:authMode`}>
        <IonPage>
          <LoginSignUpPage />
        </IonPage>
      </Route>
      <Route exact path={match.url}>
        <Redirect to={`${match.url}/${AuthMode.SIGN_UP}`} />
      </Route>
    </Switch>
  );
}
