import { Span, Text } from "@clipboard-health/ui-react";
import { Box, Skeleton } from "@mui/material";
import { CountryCode, formatPhoneNumberWithCode } from "@src/appV2/lib/utils/formatPhoneNumber";

interface StepUpAuthConfirmationTextProps {
  isLoading?: boolean;
  phoneNumber: string;
}

export function StepUpAuthConfirmationText(props: StepUpAuthConfirmationTextProps) {
  const { phoneNumber, isLoading = false } = props;

  if (isLoading) {
    return (
      <Box>
        <Skeleton variant="text" width={300} height={40} />
        <Skeleton variant="text" width={300} height={40} />
      </Box>
    );
  }

  return (
    <Text>
      To proceed, you will need to confirm your identity by entering a verification code sent to
      your phone number:{" "}
      <Span sx={{ whiteSpace: "nowrap" }}>
        <b>{formatPhoneNumberWithCode({ phoneNumber, countryCode: CountryCode.US })}</b>
      </Span>
    </Text>
  );
}
