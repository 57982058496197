import { LoadingButton } from "@clipboard-health/ui-react";
import PhoneIcon from "@mui/icons-material/Phone";
import SmsIcon from "@mui/icons-material/Sms";
import { Stack } from "@mui/material";
import { type OtpRequestChannel } from "@src/appV2/Auth/api/useRequestOtp";
import { useRef } from "react";

interface RequestOtpByChannelButtonGroupProps {
  disabled: boolean;
  requestOtp: (channel: OtpRequestChannel) => Promise<void>;
  isRequestingOtp: boolean;
}

export function RequestOtpByChannelButtons(props: RequestOtpByChannelButtonGroupProps) {
  const { disabled, requestOtp, isRequestingOtp } = props;

  const lastPickedChannel = useRef<OtpRequestChannel>();

  const onClick = async (channel: OtpRequestChannel) => {
    if (disabled) {
      return;
    }

    lastPickedChannel.current = channel;

    await requestOtp(channel);
  };

  return (
    <Stack gap={2}>
      <LoadingButton
        size="large"
        variant="outlined"
        disabled={disabled || isRequestingOtp}
        type="button"
        isLoading={isRequestingOtp && lastPickedChannel.current === "sms"}
        sx={{ flex: 1, gap: 1 }}
        onClick={async () => {
          await onClick("sms");
        }}
      >
        <SmsIcon /> SMS
      </LoadingButton>
      <LoadingButton
        size="large"
        variant="outlined"
        disabled={disabled || isRequestingOtp}
        type="button"
        isLoading={isRequestingOtp && lastPickedChannel.current === "call"}
        sx={{ flex: 1, gap: 1 }}
        onClick={async () => {
          await onClick("call");
        }}
      >
        <PhoneIcon /> Voice Call
      </LoadingButton>
    </Stack>
  );
}
